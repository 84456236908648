import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import {
  faPlus,
  faSearchMinus
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Popup from '../../../../sections/ui/Popup/Popup';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Sidebar from '../../../../sections/my-account/organizer/sidebar/Sidebar';
import Alert from '../../../../sections/ui/Alert';
import Breadcrumbs from '../../../../sections/ui/Breadcrumbs';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import EventsList from '../../../../sections/my-account/organizer/my-events/EventsList';
import axios from 'axios';
import { RESEND_TICKET,EVENTS_FILTER_API, FREE_EVENT_ALL,EVENT_TICKETS_ALL } from '../../../../APIConfig';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import { faEye, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import { useTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { API_DOMAIN } from '../../../../Config';

function FreeTicket(props) {
  const authDataString = localStorage.getItem('user');
  const authData = JSON.parse(authDataString);
  const headers = {
    Authorization: `Bearer ${authData && authData.access_token}`,
    Accept: 'application/json'
  };
 
  const initialFilters = {
    sort_by: 2,
    category_id: null,
    status: 2
  };
  const initialFormData = {
 
  };
  const [Method, setMethod] = useState('custom');
  const [message, setMessage] = useState(false);
  const [sendEmailText, setSendEmailText] = useState('');
  const [showAllFilters, setShowAllFilters] = useState(false);
  const {t, i18n} = useTranslation('common');
  const [filters, setFilters] = useState(initialFilters);
  const [catOptions, setCatOptions] = useState([]);
  const [error, setError] = useState('');
  const [eventsList, setEventsList] = useState({ data: [] });
  const perPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setisEmpty] = useState(false);
  const [reload, setReload] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [alleventData, setAllEventData] = useState([]);
  const [allticketData, setAllTicketData] = useState([]);
  const [order, setOrder] = useState();
  const [showResendPopup, setShowResendPopup] = useState(false);
  const [ticketCsvData,setTicketCsvData] = useState({})
  const {
    event_id,
    ticket_id,
  } = formData;

  const sendEmailHandler = async e => {
    setShowResendPopup(false);
    
    resendEmail(order.id, order.order_number.toString());
  };

  const showAlert = (message, error) => {
    if (error === 'error') {
      setMessage(message);
    } else if (error === 'success') {
      setMessage(message);
      alert(message)
    }
  };
  useEffect(() => {
    const getEvents = async () => {
      try {
        setLoading(true);
        const headers = {
          Authorization: `Bearer ${authData && authData.access_token}`
        };
        const { data } = await axios.post(
          `${EVENTS_FILTER_API}?page=${currentPage}`,
          filters,
          {
            headers
          }
        );
       setAllEventData(data);
       let catArray = data.myevents.data.map(cat => ({
        label: cat.title,
        value: cat.id
      }));
      setEventData(catArray);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    getEvents();
  }, [filters, currentPage, reload]);

  const resendEmail = async (id, orderid) => {
    try {
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
     
      const { data } = await axios.post(
        RESEND_TICKET,
        { orderid: orderid,to_mail:sendEmailText },
        { headers }
      );
      showAlert(t('MailSentSuccessfully'), 'success');
    } catch (err) {
      showAlert(t('EventPage.SomethingWentWrong'), 'error');
    }
  };
  
  const onChange = e => {
    setSendEmailText(e.target.value)
  };


  const getTickets = async (id) => {
    
    try {
      const { data } = await axios.post(
        EVENT_TICKETS_ALL,
        { event_id: id },
        { headers }
      );
      setAllTicketData([]);
      if (data.status && data.tickets.length > 0) {
        setAllTicketData(data.tickets);
      }
      if(data && data.tickets)
      {
  let catArray =data.tickets.map(cat => ({
    label: cat.title,
    value: cat.id
  }));
  
  catArray.unshift({
    label: t('MyEventPage.AllTickets'),
    value: 0
  })
  setTicketData(catArray);
}
  setFormData(state => ({
    ...state,
    ticket_id: {
      label: t('MyEventPage.AllTickets'),
      value: 0
    }
  }));
  getEventFree(id,0);
    } catch (err) {
    }
  };

  const getEventFree = async (eventid,ticketid) => {
    try {
      setLoading(true);
      const headers = {
        Authorization: `Bearer ${authData && authData.access_token}`
      };
      const { data } = await axios.post(
        FREE_EVENT_ALL,
        { event_id: eventid,ticket_id:ticketid },
        { headers }
      );
      if(data.data.length==0)
      {
        setisEmpty(true);
      }
      else
      {
        setTimeout(()=>{
          if ( ! $.fn.DataTable.isDataTable('#table1')) {
          $('#table1').DataTable({
            "scrollX": true,
        });
      }
        },100)
        var allheaders = [];
        if(ticketid!=0)
        {
         allheaders= [
          { label: t('CustomerName'), key: "customer_name" },
          { label: t('CustomerEmail'), key: "customer_email" },
          { label: t('AttendeeDashoboardPage.Quantity'), key: "quantity" },
          { label: t('ticketdownloaded'), key: "downloaded" },
          { label: t('ticketsscanned'), key: "checked_in" },
          { label: t('emailtimestamp'), key: "created_at" }
         ]
        }
        else
        {
          allheaders= [
            { label: t('CustomerName'), key: "customer_name" },
            { label: t('CustomerEmail'), key: "customer_email" },
            { label: t('AttendeeDashoboardPage.Quantity'), key: "quantity" },
            { label: t('AttendeeDashoboardPage.TicketType'), key: "ticket_type" },
            { label: t('ticketdownloaded'), key: "downloaded" },
            { label: t('ticketsscanned'), key: "checked_in" },
            { label: t('emailtimestamp'), key: "created_at" }
           ]
        }
        const alldata = [];
        for(var k=0;k<data.data.length;k++)
        {
          if(ticketid!=0)
          {
          alldata.push(
            { 
            customer_name: data.data[k].customer_name, 
            customer_email: data.data[k].customer_email, 
            quantity: data.data[k].quantity,
            downloaded: data.data[k].downloaded=='false'?t('No'):t('Yes'), 
            checked_in: data.data[k].checked_in, 
            created_at: moment(
              data.data[k].created_at
             ).format('DD/MM/YYYY HH:mm')
           }
          )
          }
          else
          {
            alldata.push(
              { 
              customer_name: data.data[k].customer_name, 
              customer_email: data.data[k].customer_email, 
              quantity: data.data[k].quantity, 
              ticket_type: data.data[k].ticket_title,
              downloaded: data.data[k].downloaded=='false'?t('No'):t('Yes'), 
              checked_in: data.data[k].checked_in, 
              created_at: moment(
                data.data[k].created_at
               ).format('DD/MM/YYYY HH:mm')
             }
            )
          }
        }
        setTicketCsvData(
          {
            data: alldata,
            headers: allheaders,
            filename: 'freetickets.csv'
          }
        )
      }
      setEventsList({ data: data.data });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      
    }
  };

  useEffect(() => {
    if (authDataString) {
      if (authData.user_detail.role_id !== 3) {
        props.history.push('/signin');
      }
    } else {
      props.history.push('/signin');
    }
  }, []);

 

  return (
    <>
      <Breadcrumbs active='free-tickets' />
      <section className='section-content padding-y bg-white'>
        <div className='container'>
          <div className='row'>
            <Sidebar active='free-tickets' />
            <Popup
        className='modal-lg modal-dialog-scrollable'
        openModal={showResendPopup}
        closeModal={() => setShowResendPopup(false)}
        title={
          <>
            <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
            {t('DashboardPage.FreeTickets')}
          </>
        }
        footer={
          <>
            <span className='close'></span>
            <span>
              {t('SendEmail')}
            </span>

          </>
        }
        footerAction={sendEmailHandler}
      >
        <>
          <div className='row'>
            <div className='col-12'>
            <legend className={`col-form-label`}>
            {t('SelectEmailType')}
            </legend>
            </div>
            <div className='col-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  id='custom'
                  type='radio'
                  name='Method'
                  value='custom'
                  checked={Method === 'custom'}
                  onChange={e => {
                    setMethod(e.target.value)
                    setSendEmailText('')
                  }
                  }
                />
                <label style={{ marginLeft: '10px', marginTop: '-3px' }}>
                  Custom
                </label>
              </div>
            </div>
            <div className='col-3 form-group'>
              <div className='custom-control custom-radio custom-control-inline'>
                <input
                  id='default'
                  type='radio'
                  name='Method'
                  value='default'
                  checked={Method === 'default'}
                  onChange={e => {
                    setMethod(e.target.value)
                    setSendEmailText(order.customer_email)
                  }
                  }
                />
                <label style={{ marginLeft: '10px', marginTop: '-3px' }}>
                  Default
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className='col-12 col-md-12'>
              <LabeledInput
                type='text'
                label={t('Email')}
                id='title'
                name='title'
                required
                readOnly={Method === 'default' ? true : false}
                placeholder={t('Email')}
                value={sendEmailText}
                onChange={onChange}
              />
            </div>
          </div>
        </>
        {/* <OrderDetail order={order} /> */}
      </Popup>
            <div className='col-lg-9 mt-4 mt-lg-0'>
              <div className='box shadow-none bg-gray mb-4'>
                <div className='row'>
                  <div className='col-sm-12 col-lg-12' style={
                    {
                      display:'flex',
                      justifyContent:'center'
                    }
                  }>                   
                    <Link
                      to='/dashboard/organizer/freetickets/add'
                      className='btn btn-primary ml-3'>
                      <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('MyEventPage.sendfreeticket')}
                    </Link>
                    <Link
                      to='/dashboard/organizer/freeticketsmultiple/add'
                      className='btn btn-primary ml-3'>
                      <FontAwesomeIcon icon={faPlus} className='mr-2' /> {t('MyEventPage.sendfreeticketmultiple')}
                    </Link>
                  </div>
                </div>
              </div>
              <div className='row'>
              <div className='col-sm-6 col-lg-6'>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectEvent')}
                          options={eventData}
                          name='event_id'
                          isClearable={false}
                          isSearchable={false}
                          value={event_id}
                          handleChange={e => {
                            setisEmpty(false);
                            getTickets(e.value)
                            setFormData(state => ({
                              ...state,
                              event_id: e
                            }));
                          }}
                        />
              </div>
              <div className='col-sm-6 col-lg-6'>
              <CustomSelect
                          className='custom_select-div'
                          required={true}
                          label={t('MyEventPage.SelectTicket')}
                          options={ticketData}
                          name='ticket_id'
                          isClearable={false}
                          isSearchable={false}
                          value={ticket_id}
                          handleChange={e => {
                            setisEmpty(false);
                            setFormData(state => ({
                              ...state,
                              ticket_id: e
                            }));
                            getEventFree(event_id.value,e.value);

                          }}
                        />
                  </div>
             </div> 
               
              {loading ? (
                <CustomLoader style={{ textAlign: 'center' }} />
              ) : (
                <>    
                  {isEmpty === true ? (
                    <Alert type='warning'>{t('NoFreeEventsFound')}</Alert>
                  ) :''}
                  {!loading &&event_id && ticket_id && eventsList &&
                        eventsList.data && eventsList.data.length > 0 && 
                        (
                       <>
                       <div>                       
                      <div style={{display:'flex',justifyContent:'center',marginBottom:20}}>
                       <CSVLink  {...ticketCsvData} className='btn btn-primary'>{t('ExporttoCSV')}</CSVLink>
                       </div>
                            <Table id='table1' className='table table-responsive-md table-striped custom-table'>
                              <Thead className='thead-light'>
                                <Tr>
                                  <Th>
                                    <span
                                      className='text-muted ml-2'
                                      style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEye} color='#fff' />
                                    </span>
                                  </Th>
                                  <Th>{t('CustomerName')}</Th>
                                  <Th>{t('CustomerEmail')}</Th>
                                  <Th>{t('AttendeeDashoboardPage.Quantity')}</Th>
                                  {((ticket_id.value==0)&&<><Th>{t('AttendeeDashoboardPage.TicketType')}</Th></>)}
                                  <Th>{t('ticketdownloaded')}</Th>
                                  <Th>{t('ticketsscanned')}</Th>
                                  <Th>{t('emailtimestamp')}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                              {
                              eventsList.data.map(event => (
                              <>
                              <Tr>
                              <Td>
                              <span
                               className='text-muted ml-2'
                               style={{ cursor: 'pointer' }}
                               onClick={() => {
                                setSendEmailText('')
                                setMethod("custom")
                                setOrder(event);
                               setShowResendPopup(true)
                               }}
                               title='Resend Email'>
                               <FontAwesomeIcon icon={faMailBulk} color='#31c5f4' />
                               </span>
                              </Td>
                              <Td>{event.customer_name}</Td>
                              <Td>{event.customer_email}</Td>
                              <Td>{event.quantity}</Td>
                              {((ticket_id.value==0)&&<><Td>{event.ticket_title}</Td></>)}
                              <Td>{event.ticket_download=='false'?t('No'):t('Yes')}</Td>
                              <Td>{event.checked_in}</Td>
                              <Td>{moment(
                              event.created_at
                             ).format('DD/MM/YYYY HH:mm') }</Td>
                              </Tr>
                              </>
                               ))}
                              </Tbody>
                            </Table>
                          
                            </div>
                          </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default FreeTicket;
