import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToCart, removeFromCart } from '../../../service/actions/cartActions'
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuRadioGroup,MenuDivider
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import RadioInput2 from '../../../sections/ui/formfields/RadioInput2';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { useAlert } from 'react-alert';
const TicketCounter1 = props => {
  const { ticket, event, show, show1 } = props
  //const alert = useAlert();

  const [count, setCount] = useState(0)
  const cartItems = useSelector(state => state.cart.cartItems)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('common');
  const [supplies, setSupplies] = useState(false)
  const [allow, setAllow] = useState(true)
  const [suppliesdata, setSuppliesData] = useState({})
  const [allsuppliesdata, setAllSuppliesData] = useState({})

  var maxtict = (ticket.ticket_limit <= (ticket.quantity - Number(ticket.booked_qty)) ? props.max : (ticket.quantity - Number(ticket.booked_qty)));//ticket.quantity
  if(ticket.quantity - Number(ticket.booked_qty) <= 0){
    maxtict = 0;
  }
  if(show1 === 0){
    maxtict = ticket.quantity - Number(ticket.booked_qty);
  }
  useEffect(() => {
    if (props.value) {
      setCount(props.value)
    }
    cartItems.forEach(item => {
      if(ticket && item.ticket_id === ticket.id && item.event_id === event.id){
        setCount(item.ticket_qty)
        setAllSuppliesData(item.ticket.supplements?item.ticket.supplements:[])
        setSuppliesData(item.ticket.selected_supplement?item.ticket.selected_supplement:{})      
        ticket.selected_supplement=item.ticket.selected_supplement?item.ticket.selected_supplement:{};
      }
    })
    
  }, [props.value])
  const changeHandler = e => {
    e.target.value=count;
  }
  useEffect(()=>{
     let by_default = false;
    if(ticket.supplements.length!=0)
    {
      ticket.supplements.forEach(supplement=>{
        if(supplement.supplement_type!=2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)))
        {
          by_default = true;
        }
        if( supplement.supplement_type==2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0))) 
        {
          by_default = true
        }
      })
     setAllow(by_default)
    }
   
},[count])
  const clickHandler = val => {
    ticket.selected_supplement=suppliesdata;
    if (val === 'add' && count < +(maxtict)) {
      if(ticket.supplements.length>0)
     {
       let supplements=[];
       for(var y=0;y<ticket.supplements.length;y++)
       {
        if(ticket.supplements[y].supplement_type!=2)
        {
          
        if(ticket.supplements[y].supplement_applied==1 || (ticket.supplements[y].supplement_applied==2 && count==0))
        {
          if(ticket.supplements[y].supplement_type==1)
          {
         if($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).is(':checked')==false && ticket.supplements[y].supplement_required==1)
         {
          alert(ticket.supplements[y].supplement_title+" is Required")
          return
         }
         else
         {
          let res = $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).is(':checked')
          supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:res,type:ticket.supplements[y].supplement_type,price:res?ticket.supplements[y].supplement_price:0,supplement_applied:ticket.supplements[y].supplement_applied})
         }
        }
        else
      {
        if(!$("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).val() && ticket.supplements[y].supplement_required==1)
        {
         alert(ticket.supplements[y].supplement_title+" is Required")
         return
        }
        else
        {
         let res = $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).val()
         supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:res,type:ticket.supplements[y].supplement_type,price:0,supplement_applied:ticket.supplements[y].supplement_applied})
        }
      }
      }
        }
        else
        {
          if(ticket.supplements[y].supplement_applied==1 || (ticket.supplements[y].supplement_applied==2 && count==0))
          {
          if(ticket.supplements[y].supplement_multiple_type==1)
          {
         if(!$("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val() && ticket.supplements[y].supplement_required==1)
         {
         alert(ticket.supplements[y].supplement_title+" is Required") 
         return
         }
         else
         {
          if($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())
          {supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[JSON.parse(ticket.supplements[y].supplement_option)[parseInt($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())]],type:ticket.supplements[y].supplement_type
          ,price:JSON.parse(ticket.supplements[y].supplement_option)[parseInt($("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id+" input[type=radio]:checked").val())].price,supplement_applied:ticket.supplements[y].supplement_applied,multi:1})
         }
          else
          supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[],type:ticket.supplements[y].supplement_type,price:0,supplement_applied:ticket.supplements[y].supplement_applied,multi:1}) 
        }
          }
          else
          {
            var selecetedlist=[];
              $("#"+ticket.id+'-counter-popup' +' .check-'+ticket.supplements[y].id).each(function () {
               if(this.checked)
               selecetedlist.push($(this).val())
              });
            if(selecetedlist.length==0 && ticket.supplements[y].supplement_required==1)
            {
            alert(ticket.supplements[y].supplement_title+" is Required") 
            return
            }
            else
            {
             if(selecetedlist.length!=0)
             {
              let pricetotal=0;
              let options = [];
              for(var newt=0;newt<selecetedlist.length;newt++)
              {
                options.push(JSON.parse(ticket.supplements[y].supplement_option)[selecetedlist[newt]])
                pricetotal+=parseFloat(JSON.parse(ticket.supplements[y].supplement_option)[selecetedlist[newt]].price);
              }
              supplements.push({title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:options,type:ticket.supplements[y].supplement_type
             ,price:pricetotal,supplement_applied:ticket.supplements[y].supplement_applied,multi:0})
            }
             else
             supplements.push({price:0,title:ticket.supplements[y].supplement_title,id:ticket.supplements[y].id,value:[],type:ticket.supplements[y].supplement_type,supplement_applied:ticket.supplements[y].supplement_applied,multi:0}) 
           }
          }
        }
        }
        }
        let keyuse = (count+1)+"";
        if(ticket.selected_supplement)
        ticket.selected_supplement[keyuse]=supplements;
        else
        {
          ticket.selected_supplement={}
          ticket.selected_supplement[keyuse]=supplements;
        }
        setSupplies(true)
        setSuppliesData(ticket.selected_supplement)
     }
      setCount(count + 1)
      dispatch(addToCart(event.id, ticket.id, count + 1, event, ticket))
    } else if (val === 'sub' && !(count === 0 || !Number.isInteger(count))) {
      if (count - 1 <= 0) {
        if(ticket.supplements.length>0)
        {
        ticket.selected_supplement={};
        setSuppliesData(ticket.selected_supplement)
        }
        dispatch(removeFromCart(event.id, ticket.id))
      } else {
        if(ticket.supplements.length>0)
        {
          let keyuse = count+"";
          delete ticket.selected_supplement[keyuse];
          setSuppliesData(ticket.selected_supplement)
        }
        dispatch(addToCart(event.id, ticket.id, count - 1, event, ticket))
      }
      setCount(count - 1)
    } else {
      return false
    }
    $(".checkboxmy").prop('checked', false);
    $(".text-input-mine").val('');
  }

  return (
    <>
    {maxtict <= 0 ? '' : 
      <div className={`${props.label ? 'form-group' : ''}`}>
        {props.label ? (
          <label className={`${props.required ? 'required' : ''}`}>
            {props.label} 
          </label>
        ) : (
          ''
        )}
        {props.info ? (
          <small className='form-text text-muted mb-3'>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className='text-primary mr-1'
            />
            {props.info}
          </small>
        ) : (
          ''
        )}

        <div className='input-group bootstrap-touchspin bootstrap-touchspin-injected'>
          {props.isPrice ? (
            <span className='input-group-addon input-group-prepend bootstrap-touchspin-prefix'>
              <span className='input-group-text'>$</span>
            </span>
          ) : (
            ''
          )}
          <input
            type='text'
            className={`imp-white form-control touchspin-integer eventdate-ticket-qte ${
              props.bg_gray ? '' : 'bg-white'
            }`}
            value={count}
            onChange={e => changeHandler(e)}
          />
          <span className='input-group-btn-vertical'>
            {
              (ticket.supplements.length==0 || !allow) &&<><button
              className='btn btn-primary bootstrap-touchspin-up '
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
              onClick={() => clickHandler('add')}
            >
              +
            </button></>
            }
            {ticket.supplements.length!=0 && allow && 
              <>
               <Menu id={ticket.id+'-counter-popup'} menuButton={<MenuButton style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}} className='btn btn-primary bootstrap-touchspin-up '>
                <button
              className='remove-design'
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
            >
              +
            </button>
               </MenuButton>} transition>
                {
                 ticket.supplements.map(supplement => (
                  <>
                  {
                    supplement.supplement_type!=2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) && <>
                      <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                      {
                  supplement.supplement_type==1 && <>
                 <input
                  type='checkbox'
                  className={`checkboxmy check-${supplement.id}`}
                  id={supplement.id+','+ticket.id}
                  name={supplement.id+','+ticket.id}
                  placeholder={supplement.supplement_title}
                  style={{ marginRight: 5 }}
                />
                </>
                 }
                {
                  supplement.supplement_type==1 && <>
                  <label className={supplement.supplement_required==1?'required':''} htmlFor={supplement.id+','+ticket.id} style={{margin:'0px'}}>{supplement.supplement_title} €{supplement.supplement_price}</label>
                  </>
                }
                {
                  supplement.supplement_type==3 && <>
                    <div className='form-group'>
                   <label className={supplement.supplement_required==1?'required':''} htmlFor={supplement.id+','+ticket.id} style={{margin:'0px',float:'left',marginLeft:'5px',marginBottom:'5px'}}>{supplement.supplement_title}</label>
                   <input
                    className={`form-control text-input-mine checkboxmy check-${supplement.id}`}
                    id={supplement.id+','+ticket.id}
                    style={{ height: 30,marginTop:'8px' }}
                    type='text'
                    placeholder={supplement.supplement_title}
                   
                  />
                   </div>
                  </>
                }
                
              
              </div>
                    </>
                  }
                  {
                    supplement.supplement_type==2 && (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) && <>
                      <div>
                      {
                        supplement.supplement_multiple_type==1 && 
                        <>
                        <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                        <RadioInput2
                      className={`custom-radios check-${supplement.id}`}
                            
                            label={supplement.supplement_title}
                            required={supplement.supplement_required==1?true:false}
                            info={''}
                            entries={
                              JSON.parse(supplement.supplement_option).map((option,index)=>{
                                return  {
                                  label: option.title +" €" + option.price,
                                  value: index.toString(),
                                  id: option.title+','+index+','+supplement.id+','+ticket.id,
                                  name:supplement.supplement_title
                                }
                              })
                            
                            }
                          
                          />
                          </div>
                        </>
                      }
                      {
                        supplement.supplement_multiple_type==0 && 
                        <>
                        <div>
                          
                       
                        <label className={`label-style ${supplement.supplement_required ? 'required' : ''}`}>
                          {supplement.supplement_title}
                        </label>
                        {
                  JSON.parse(supplement.supplement_option).map((option,index) => (
                  <>
                   <div className='col-12 col-md-12' style={{display:'flex',alignItems:'center'}}>
                   <input
                  type='checkbox'
                  className={`checkboxmy custom-checks check-${supplement.id}`}
                  id={option.title+','+index+','+supplement.id+','+ticket.id}
                  name={option.title+','+index+','+supplement.id+','+ticket.id}
                  placeholder={option.title}
                  style={{ marginRight: 5 }}
                  value={index.toString()}
                />
                  <label className={supplement.supplement_required==2?'required':''} htmlFor={option.title+','+index+','+supplement.id+','+ticket.id} style={{margin:'0px'}}>{option.title} €{option.price}</label>
                   </div>
                 
                </>
               
                 ))}
                   </div>
                        </>
                      }
                      
              </div>
                    </>
                  }
                  {
                    (supplement.supplement_applied==1 || (supplement.supplement_applied==2 && count==0)) &&
                    <>
                    <MenuDivider   />
                    </>
                  }
             
                  </>
                 
                ))}
              <MenuItem onClick={() => clickHandler('add')}><b style={{color:'#31c5f4'}}>{t('continue')}</b></MenuItem>
             </Menu>

        </>
            }
            
            <button
              className='btn btn-primary bootstrap-touchspin-down '
              type='button'
              style={props.isFrame?{backgroundColor:props.buttonColor,borderColor:props.buttonColor}:{}}
              onClick={() => clickHandler('sub')}
            >
              -
            </button>
          </span>
        </div>
      </div>
}
{ticket.buy_limit>1 && (
        <span className='badge badge-info mt-2'>
          {ticket.buy_limit} min ticket limit
        </span>
      )}
      {props.ticketLeftText !== false && ticket && ticket.quantity && show === 1 ? (
        <span className='badge badge-info mt-2'  style={props.isFrame?{backgroundColor:props.urlColor,borderColor:props.urlColor}:{}}>
          {(ticket.quantity - Number(ticket.booked_qty)) <= 0 ? '' : (ticket.quantity - Number(ticket.booked_qty))} tickets left
        </span>
      ) : ''}
      
      
    </>
  )
}
export default TicketCounter1
