import Card from '../../../../sections/ui/Card';
import LabeledInput from '../../../../sections/ui/formfields/LabeledInput'; 
import { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import CustomLoader from '../../../../sections/ui/CustomLoader';
import CustomSelect from '../../../../sections/ui/formfields/CustomSelect';
import { EVENT_TICKET_ADD, BACKEND_URL } from '../../../../APIConfig';
import { useAlert } from 'react-alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useTranslation } from 'react-i18next';
import RadioInput2 from '../../../../sections/ui/formfields/RadioInput2';
import CustomFileInput2 from '../../../../sections/ui/formfields/CustomFIleInput2';
import CustomCheckbox from '../../../../sections/ui/formfields/CustomCheckbox';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DateInput from '../../../../sections/ui/formfields/LabededDateField';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import $ from 'jquery';

const TicketForm = ({
  ticket,
  eventId,
  token,
  eventdetail,
  coupons: initialCoupons,
  taxes: initialTaxes,
  supplements: initialSupplements,
  setReloadTickets,
  template,
  ticketStat,
  template_image,
  images,
  ticketiframe,
  admin_commission_type, 
  org_comm_type,
  admin_commission,
  organizer_commission,
}) => {
  const initialFormData = {
    title: ticket?ticket.title:'',
    price: ticket?ticket.price:'',
    quantity: ticket?ticket.quantity:'',
    description: ticket?ticket.description=='null'?'':ticket.description:'',
    event_id: eventId,
    start_date:new Date(),
    start_time:ticket?(ticket.start_time?ticket.start_time:moment().format('HH:mm:ss')):moment().format('HH:mm:ss'),
    expire_date:new Date(eventdetail.end_date),
    expire_time:ticket?(ticket.expire_time?ticket.expire_time:eventdetail.end_time):eventdetail.end_time,
    ticket_id: ticket?ticket.ticket_id:'',
    taxes_ids: ticket?ticket.taxes_ids:[],
    order: ticket?ticket.order:'',
    featured: ticket?ticket.featured:0,
    sold_out:ticket?ticket.sold_out:0,
    ticket_limit: ticket?ticket.ticket_limit:0,
    status: ticketStat,
    template_image: ticket?ticket.template_image:null,
    template: template,
    images: ticket?ticket.images:null,
    scan_allow:ticket?ticket.scan_allow:1,
    buy_limit:ticket?ticket.buy_limit:1,
    iframe_allow:ticketiframe,
    free_ticket_allow:ticket?ticket.free_ticket_allow:0,
    free_ticket_quantity:ticket?ticket.free_ticket_quantity:0,
    free_ticket_get:ticket?ticket.free_ticket_get:0,
    scan_per_hour:ticket?ticket.scan_per_hour:0,
    hour_limit:ticket?ticket.hour_limit:0,
    admin_commission_type: ticket?ticket.admin_commission_type:0,
    organizer_commission_type: {
      label: ticket?(ticket.organizer_commission_type == 1 ? 'Fixed' : ticket.organizer_commission_type == 2 ? 'Percentage' : ''):'',
      value:  ticket?ticket.organizer_commission_type:0
    },
    admin_commission: ticket?ticket.admin_commission:0,
    organizer_commission: ticket?ticket.organizer_commission:0,
  };
  const [templateName, setTemplateName] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const { t, i18n } = useTranslation('common');
  const [IsCustomTemplate, setCustomTemplate] = useState(false);
  const [IsNew, setIsNew] = useState(false);
  const [ticketStatus, setticketStatus] = useState();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const [selectedFile, setSelectedFile] = useState();
  const taxes = initialTaxes.map(tax => ({
    label: tax.title,
    value: tax.id
  }));

  const coupons = initialCoupons.map(coupon => ({
    label: coupon.coupon_code,
    value: coupon.id
  }));

  const supplements = initialSupplements.map(supplement => ({
    label: supplement.supplement_title,
    value: supplement.id
  }));

  const addImageGallery = event => {

  };

  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [commTypeData, setcommTypeData] = useState([{ label: 'Fixed', value: 1 }, { label: 'Percentage', value: 2 }]);
  useEffect(() => {
    if (ticket) {
      const currentCoupon =
        ticket.coupon_id && ticket.coupon_id.length > 0
          ? ticket.coupon_id.map(coupon =>
            coupons.find(coup => coup.value === +coupon)
          )
          : [];
          const currentSupplement =
          ticket.supplement_id && ticket.supplement_id.length > 0
            ? ticket.supplement_id.map(supplement =>
              supplements.find(soup => soup.value === +supplement)
            )
            : [];

      setSelectedTaxes(
        ticket.taxes.length > 0
          ? ticket.taxes.map(tax => ({
            label: tax.title,
            value: tax.id
          }))
          : []
      );
      const currentCouponsUpdated = currentCoupon.filter(elm => elm)
      const currentSupplementUpdated = currentSupplement.filter(elm => elm)
      setSelectedCoupons(currentCouponsUpdated);
      setSelectedSupplements(currentSupplementUpdated)

      setFormData({
        ...ticket,
        expire_date:ticket.expire_date === '0000-00-00' || !ticket.expire_date
        ? new Date(eventdetail.end_date)
        : new Date(ticket.expire_date)
       ,
       expire_time :ticket.expire_time
       ? ticket.expire_time
       : eventdetail.end_time,
       start_date:ticket.start_date === '0000-00-00' || !ticket.start_date
        ? new Date(eventdetail.start_date)
        : new Date(ticket.start_date)
       ,
       start_time :ticket.start_time
       ? ticket.start_time
       : eventdetail.start_time,
       admin_commission_type: ticket.admin_commission_type,
       organizer_commission_type: {
         label: ticket.organizer_commission_type == 1 ? 'Fixed' : ticket.organizer_commission_type == 2 ? 'Percentage' : '',
         value: ticket.organizer_commission_type
       },
       admin_commission: ticket.admin_commission,
       organizer_commission: ticket.organizer_commission,
       
      })

      if (template === 'custom') {
        setTemplateName(ticket.template_image);
        setCustomTemplate(true);
        setIsNew(false);
      }
    }
  }, [ticket]);

  const onChange = e => {
    if (e.target.name === 'template') {
      if (e.target.value === 'standard') {
        setCustomTemplate(false);

      }
      else {
        setCustomTemplate(true);
      }
    }
    else {
      setFormData(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value
      }));
    }
  };

  const formSubmit = async e => {
    e.preventDefault();
    const taxString =
      selectedTaxes.length > 0
        ? selectedTaxes.map(tax => tax.value).join()
        : '';

    const couponString =
      selectedCoupons.length > 0
        ? selectedCoupons.map(coupon => coupon.value).join()
        : '';
    
        const supplementString =
        selectedSupplements.length > 0
          ? selectedSupplements.map(supplement => supplement.value).join()
          : '';

    if (!token) {
      return;
    }

    const data = {
      ...formData,
      ticket_id: ticket ? ticket.id : '',
      taxes_ids: taxString,
      coupon_id: couponString,
      supplement_id : supplementString,
      price: formData.price.replace(',', '.'),
      expire_date: moment(formData.expire_date).format('YYYY-MM-DD'),
      start_date: moment(formData.start_date).format('YYYY-MM-DD'),
      template: IsCustomTemplate ? 'custom' : 'standard',
      organizer_commission_type:formData.organizer_commission_type.value
    };

    const newFormData = new FormData();
    for (let key in data) {
      if (key === 'template_image') {

      } else {
        newFormData.append(key, data[key]);
      }
    }
    if (data.template === 'custom') {
      var file = $('#images')[0].files[0];
      if(file)
     {
     const filesize = (file.size / (1024*1024)).toFixed(2);

     if(filesize<=2)
     {
      newFormData.append(`template_image`, file);
     }
     else
     {
      alert.error(t('maxlimit'));
      return;
     } 
    }
    }

    const headers = {
      accept: 'application/json',
      Authorization: `Bearer ${token}`
    };

    try {
      setLoading(true);
      await axios.post(EVENT_TICKET_ADD, newFormData, { headers });
      alert.success(`Ticket ${ticket ? 'updated' : 'added'}`);
      setReloadTickets(state => !state);
    } catch (err) {

      alert.error(t('SomethingWentWrong'));
    }

    setLoading(false);
  };
  return (
    <>
      <div className='col-lg-12'>
        <Card>
          <form onSubmit={formSubmit}>
            <div className='row'>
         
              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='text'
                  label={t('TicketTitle')}
                  id='title'
                  name='title'
                  required
                  placeholder={t('TicketTitle')}
                  value={formData.title}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('MyEventPage.StartDate')}
                            type='text'
                            required
                            id='start_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                start_date: date
                              }));
                            }}
                            startDate={formData.start_date}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='startTime'>
                          {t('MyEventPage.StartTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='startTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(formData.start_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                start_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                   <div className='col-12 col-md-6'>
                          <DateInput
                            label={t('ExpireDate')}
                            type='text'
                            required
                            id='expire_date'
                            setStartDate={date => {
                              setFormData(state => ({
                                ...state,
                                expire_date: date
                              }));
                            }}
                            startDate={formData.expire_date}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <label htmlFor='expireTime'>
                          {t('ExpireTime')}<span style={{ color: 'red' }}>*</span>
                          </label>
                          <TimePicker
                            id='expireTime'
                            showSecond={false}
                            className='form-control'
                            defaultValue={moment(formData.expire_time, [
                              moment.ISO_8601,
                              'HH:mm'
                            ])}
                            allowEmpty={false}
                            onChange={e =>
                              setFormData(state => ({
                                ...state,
                                expire_time: e.format('HH:mm:ss')
                              }))
                            }
                            use12Hours={false}
                          />
                        </div>
                        {
                          formData.admin_commission_type != 0 && <>
                                <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_commission_type" className="required">{t('EventPage.AdminCommissionType')}</label>
                            <input type="text" id="admin_commission_type" name="admin_commission_type"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={formData.admin_commission_type == 1 ? 'Fixed' : formData.admin_commission_type == 2 ? 'Percentage' : ''} />
                          </div>
              </div>
              <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label for="admin_commission" className="required">{t('MyEventPage.Commission')}</label>
                            <input type="text" id="admin_commission" name="admin_commission"
                              className="form-control undefined" autocomplete="off" label="Commission" readOnly={true} value={formData.admin_commission} />
                          </div>
              </div>
                          </>
                        }
                 <div className='col-12 col-md-6'>
                          <CustomSelect
                            className='custom_select-div'
                            required={true}
                            label= {t('MyEventPage.OrgCommissionType')}
                            options={commTypeData}
                            name='organizer_commission_type'
                            isClearable={false}
                            value={formData.organizer_commission_type}
                            
                            handleChange={e => {
                              setFormData(state => ({
                                ...state,
                                organizer_commission_type: e
                              }));
                            }}
                          />
                        </div>
                        <div className='col-12 col-md-6'>
                          <LabeledInput
                            label={t('MyEventPage.Commission')}
                            type='text'
                            required
                            id='organizer_commission'
                            name='organizer_commission'
                            value={formData.organizer_commission}
                            onChange={onChange}
                            
                          />
                        </div> 
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('CouponPage.CouponType')}
                  options={coupons}
                  name='coupon_type'
                  isSearchable={false}
                  isMulti
                  value={selectedCoupons}
                  handleChange={e => setSelectedCoupons(e)}
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('supplements_type')}
                  options={supplements}
                  name='supplement_type'
                  isSearchable={false}
                  isMulti
                  value={selectedSupplements}
                  handleChange={e => setSelectedSupplements(e)}
                />
              </div>
              <div className='col-12 col-md-6'>
                <CustomSelect
                  className='custom_select-div'
                  label={t('Tax')}
                  options={taxes}
                  name='taxes'
                  isSearchable={false}
                  isMulti
                  value={selectedTaxes}
                  handleChange={e => setSelectedTaxes(e)}
                />
              </div>

              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='text'
                  label={t('Price')}
                  id='price'
                  name='price'
                  required
                  placeholder={t('Price')}
                  value={formData.price.replace('.', ',')}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>

              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('TicketLimit')}
                  id='ticket_limit'
                  name='ticket_limit'
                  required
                  placeholder={'TicketLimit'}
                  value={formData.ticket_limit}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>

              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('Quantity')}
                  id='quantity'
                  name='quantity'
                  required
                  placeholder={t('Quantity')}
                  value={formData.quantity}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('buy_limit')}
                  id='buy_limit'
                  name='buy_limit'
                  min="1"
                  required
                  placeholder={t('buy_limit')}
                  value={formData.buy_limit}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('scan_allow')}
                  id='scan_allow'
                  name='scan_allow'
                  min="1"
                  required
                  placeholder={t('scan_allow')}
                  value={formData.scan_allow}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              {
               formData.scan_allow>1 &&
                <>
                 <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('scanlimit')}
                  id='scan_per_hour'
                  name='scan_per_hour'
                  min="0"
                  max={formData.scan_allow}
                  required
                  placeholder={t('scanlimit')}
                  value={formData.scan_per_hour}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              <div className='col-12 col-md-6'>
                <LabeledInput
                  type='number'
                  label={t('perhourlimit')}
                  id='hour_limit'
                  name='hour_limit'
                  min="0"
                  required
                  placeholder={t('perhourlimit')}
                  value={formData.hour_limit}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
                </>
              }
           
              <div className='col-12 col-md-12'>
                <label htmlFor='Description'>
                  {t('EventDetailPage.Description')}<span style={{ color: '#e95b35' }}>*</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    height: 300,
                    removePlugins: ['MediaEmbed']
                  }}
                  data={formData.description ? formData.description : ''}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFormData(state => ({
                      ...state,
                      description: data
                    }));
                  }}
                />
              </div>

              <div className='col-12 col-md-12' style={{marginTop:'20px'}}>
                <input
                  type='checkbox'
                  id='featured'
                  name='featured'
                  checked={formData.featured}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      featured: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='featured'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='featured'>{t('MarkAsFeatured')}</label>
              </div>

              <div className='col-12 col-md-12'>
                <LabeledInput
                  type='number'
                  label={t('Order')}
                  id='order'
                  name='order'
                  placeholder={t('Order')}
                  value={formData.order ? formData.order : ''}
                  onChange={onChange}
                  onWheel={e => e.target.blur()}
                />
              </div>
              {
                 formData.status == 1 ? 
                 <>
                 <div className='col-12 col-md-12'>
                 <input
                  type='checkbox'
                  id='sold_out'
                  name='sold_out'
                  checked={formData.sold_out}
                  onChange={e =>
                    setFormData(state => ({
                      ...state,
                      sold_out: e.target.checked ? 1 : 0
                    }))
                  }
                  placeholder='sold_out'
                  style={{ marginRight: 5 }}
                />
                <label htmlFor='sold_out'>{t('MyEventPage.SoldOut')}</label>
              </div>
                 </>:''
              }

              <div className='col-12 col-md-12'>

                <RadioInput2
                  label={t('CouponPage.Status')}
                  info=''
                  entries={[
                    {
                      label: t('Active'),
                      value: '1',
                      id: 'enable_status',
                      name: 'status',
                      selected: formData.status === 1 ? true : false
                    },
                    {
                      label: t('Inactive'),
                      value: '0',
                      id: 'disable_status',
                      name: 'status',
                      selected: formData.status === 0 ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('AllowIframe')}
                  info=''
                  entries={[
                    {
                      label:  t('MyEventPage.Enable'),
                      value: '1',
                      id: 'enable_iframe_allow',
                      name: 'iframe_allow',
                      selected: formData.iframe_allow === 1 ? true : false
                    },
                    {
                      label: t('MyEventPage.Disable'),
                      value: '0',
                      id: 'disable_iframe_allow',
                      name: 'iframe_allow',
                      selected: formData.iframe_allow === 0 ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('AllowFreeTicket')}
                  info=''
                  entries={[
                    {
                      label:  t('MyEventPage.Enable'),
                      value: '1',
                      id: 'enable_free_ticket',
                      name: 'free_ticket_allow',
                      selected: formData.free_ticket_allow === 1 ? true : false
                    },
                    {
                      label: t('MyEventPage.Disable'),
                      value: '0',
                      id: 'disable_free_ticket',
                      name: 'free_ticket_allow',
                      selected: formData.free_ticket_allow === 0 ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              {formData.free_ticket_allow==1 && (
               <>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FreeTicketQuantity')}
                            type='number'
                            min='0'
                            step='1'
                            id='free_ticket_quantity'
                            name='free_ticket_quantity'
                            value={formData.free_ticket_quantity}
                            onChange={onChange}
                            required={true}
                          />
                </div>
                <div className='col-12 col-md-12'>
                <LabeledInput
                            label={t('FreeTicketGet')}
                            type='number'
                            min='0'
                            step='1'
                            id='free_ticket_get'
                            name='free_ticket_get'
                            value={formData.free_ticket_get}
                            onChange={onChange}
                            required={true}
                          />
                </div>
               </>
             )}
              <div className='col-12 col-md-12'>
                <RadioInput2
                  label={t('TicketTemplateType')}
                  info=''
                  entries={[
                    {
                      label: 'Standard',
                      value: 'standard',
                      id: 'enable_ticketLimitPurchase',
                      name: 'template',
                      selected: template == 'standard' ? true : false
                    },
                    {
                      label: 'Custom',
                      value: 'custom',
                      id: 'disable_ticketLimitPurchase',
                      name: 'template',
                      selected: template == 'custom' ? true : false
                    }
                  ]}
                  onChange={onChange}
                />
              </div>
              {IsCustomTemplate === true && (
                <div className='col-12 col-md-12'>
                  {templateName && IsNew === false && (
                    <img src={BACKEND_URL + templateName} className="img-fluid"/>
                  )}
                  <CustomFileInput2
                    label={t('AddTemplate')}
                    id='images'
                    info={t('MyEventPage.CustomTemplateWarning')}
                    name='images'
                    handleFile={(e) => {
                      setTemplateName(e.target.files[0].name);
                      setIsNew(true);
                    }}
                  filename={
                    templateName
                      ? templateName
                      : ''
                  }
                  />
                  <small className='form-text text-muted mb-3' style={{marginTop:'-20px'}}>
                             <FontAwesomeIcon
                               icon={faInfoCircle}
                               className='text-primary mr-1'
                              />
                            {t('maxlimit')}
                            </small>
                </div>
              )}
            </div>
            {loading && <CustomLoader />}
            {!loading && !ticket && (
              <button className='btn btn-primary ml-3 rm-margin'>{t('CouponPage.CreateButton')}</button>
            )}

            {!loading && ticket && (
              <button className='btn btn-primary ml-3 rm-margin'>{t('CouponPage.UpdateButton')}</button>
            )}
          </form>
        </Card>
      </div>
    </>
  );
};

export default TicketForm;
