import {
  faCartPlus,
  faHeart,
  faTicketAlt,
  faUser,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart,faShareSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../sections/events/EventDtlsBreadCrumbs';
import EventSlider from '../../../sections/events/EventsDetailsSlider';
import StarRating from '../../../sections/ui/StarRating.js';
import Popup from '../../../sections/ui/Popup/Popup';
import ReviewScoreBoard from '../../../sections/ui/Review/ReviewScoreBoard';
import Ticket from '../../../sections/ui/Ticket/Ticket';
import Alert from '../../../sections/ui/Alert';
import ReactTooltip from 'react-tooltip';
import {PhotoSwipeGallery} from 'react-photoswipe';
import { useAlert } from 'react-alert';
import 'react-photoswipe/lib/photoswipe.css';
import axios from 'axios';
import parse from 'html-react-parser';
import { EVENT_BY_SLUG_API, BACKEND_URL } from '../../../APIConfig';
import ReviewList from '../../../sections/ui/Review/ReviewList';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CART_ADD_ITEM_TO_STORAGE } from '../../../service/constants/cartConstants';
import { API_DOMAIN, IMAGE_BASE_URL } from '../../../Config';
import { clearCart,removeFromCart } from '../../../service/actions/cartActions';

import { useHistory } from 'react-router-dom';
import CustomLoader from '../../../sections/ui/CustomLoader';
import { FacebookShareButton, FacebookIcon, EmailShareButton } from "react-share";
import Audience from './Audience';
import {useTranslation} from "react-i18next"; 
import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
import { faMailchimp } from '@fortawesome/free-brands-svg-icons';
import { useLocation } from "react-router-dom";

function EventDetails(props) {

  const {t, i18n} = useTranslation('common')
  const [galleryImages, setGalleryImages] = useState([]);

  const slug = props.match.params.event;
  const eventid = props.match.params.eventid;
  const cartItems = useSelector(state => state.cart.cartItems)

  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const [averageReview, setAverageReview] = useState(0);
  const [averageReviewArray, setAverageReviewArray] = useState([]);

  const [read_more, setMore] = useState(false);
  const [lessText, setlessthan] = useState(false);
  // const [addToCalendar, setAddToCalendar] = useState(false);
  const [ticketsPopup, setTicketsPopup] = useState(false);
  const [invitePopup, setInvitePopup] = useState(false);
  const [reviewAdded, setReviewAdded] = useState(false);
  const [currentUserReview, setCurrentUserReview] = useState();
  const [isEventExpired, setisEventExpired] = useState(false);

  const [loadingFav, setLoadingFav] = useState(false);
  const [isFav, setIsFav] = useState(false);
  // const [favId, setFavId] = useState();
  // const [event, setEvent] = useState({
  //   title: '',
  //   start: new Date()
  // });
  const [eventDetailstate, setEventDetailstate] = useState({
    booked_tickets: [],
    currency: '',
    ticket_data: [],
    tickets: [],
    total_capacity: '',
    data: ''
  });

  const [loading, setLoading] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [showEvent, setShowEvent] = useState(false);
  const [eventPassword, setEventPassword] = useState('');
  const [country, setCountry] = useState();
  const [senderName, setsenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [ReceiverEmail, setReceiverEmail] = useState('');
  const [ReceiverMessage, setReceiverMessage] = useState('');
  const alert = useAlert();
  const history = useHistory();
  const authUser = localStorage.getItem('user');
  const authData = JSON.parse(authUser);

  // set header for sending requests
  const headers = {
    Authorization: `Bearer ${authData && authData.access_token}`,
    accept: 'application/json'
  };

  const countries = useSelector(state => state.countries.countries);

  const getId = url => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  useEffect(() => {
    getEventDetails();
    checkandremove();
  }, [countries]);

  useEffect(() => {
    getReviews();
  }, [reviewAdded]);

  const InvitePopupClick = () => {
    setInvitePopup(!invitePopup);
  };

  useEffect(() => {
    const getFavorite = async () => {
      if (authData) {
        try {
          setLoading(true);
          const { data } = await axios.post(
            `${API_DOMAIN}api/favorite/show`,
            {},
            { headers }
          );
          const currentIsFav = data.data.find(fav => +eventid === fav.event_id);
          if (currentIsFav) {
            setIsFav(true);
            // setFavId(currentIsFav.id);
          }
        } catch (error) {
          console.error(error);
          // alert.error('Something went wrong please try after some time.');
        }
        setLoading(false);
      }
    };
    getFavorite();
  }, []);

  const addToFavouriteHandler = async eventId => {
    if (authData) {
      try {
        setLoadingFav(true);
        await axios.post(
          `${API_DOMAIN}api/favorite/add`,
          { event_id: eventId },
          { headers }
        );
        alert.success(t('AddedToFavorite'));
        setLoadingFav(false);
        setIsFav(true);
      } catch (error) {
        alert.error(t('AddedToFavoriteError'));
        setLoadingFav(false);
        setIsFav(false);
      }
    } else {
      history.replace('/signin');
    }
    setLoadingFav(false);
  };

  const removeFromFavoriteHandler = async eventId => {
    if (authData) {
      try {
        setLoadingFav(true);
        await axios.get(`${API_DOMAIN}api/favorite/delete/${eventId}`, {
          headers
        });
        alert.success(t('RemovedToFavorite'));
        setLoadingFav(false);
        setIsFav(false);
      } catch (error) {
        alert.error(t('AddedToFavoriteError'));
        setLoadingFav(false);
        setIsFav(true);
      }
    } else {
      history.replace('/signin');
    }
    setLoadingFav(false);
  };

  const getReviews = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        API_DOMAIN + `api/review/show/${eventid}`
      );
      setReviews(data.data);
      const average = data.data.map(review => review.review);
      let star1 = 0;
      let star2 = 0;
      let star3 = 0;
      let star4 = 0;
      let star5 = 0;
      average.forEach(avg => {
        if (avg === 1) {
          star1++;
        }
        if (avg === 2) {
          star2++;
        }
        if (avg === 3) {
          star3++;
        }
        if (avg === 4) {
          star4++;
        }
        if (avg === 5) {
          star5++;
        }
      });
      const calAverage = [star5, star4, star3, star2, star1];
      if (authData) {
        const currentUserId = authData.user_detail.id;
        const cUserReview = data.data.find(
          review => review.user_id === currentUserId
        );

        if (cUserReview) {
          setCurrentUserReview({
            review: cUserReview.review,
            description: cUserReview.description
          });
        }
      }
      // (5*252 + 4*124 + 3*40 + 2*29 + 1*33) / (252+124+40+29+33) = 4.11 and change
      const starAverage =
        (5 * calAverage[0] +
          4 * calAverage[1] +
          3 * calAverage[2] +
          2 * calAverage[3] +
          1 * calAverage[4]) /
        calAverage.reduce((a, b) => a + b, 0);

      setAverageReviewArray(calAverage);
      setAverageReview(Math.ceil(Math.ceil(starAverage)));
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getEventDetails = async () => {
    try {
      setLoading(true);
      await axios({
        url: EVENT_BY_SLUG_API + slug,
        method: 'GET'
      }).then(function (response) {
        setEventDetailstate({
          booked_tickets: response.data.events.booked_tickets,
          currency: response.data.events.currency,
          ticket_data: response.data.events.ticket_data,
          tickets: response.data.events.tickets,
          total_capacity: response.data.events.total_capacity,
          data: response.data.events.data,
          images: JSON.parse(response.data.events.data.images)
        });
        if (countries) {
          let country = countries.find(
            country => country.id === response.data.events.data.country_id
          );
          setCountry(country);
        }
        let  end_date = moment(response.data.events.data.end_date+" "+response.data.events.data.end_time);
        let server_date = moment(response.data.events.data.server_date);
        if(end_date.isSameOrAfter(server_date)){
          setisEventExpired(false)
        }
        else
        {
          if(response.data.events.data.redirect_url)
          window.location=response.data.events.data.redirect_url
          else
          setisEventExpired(true)
        }
        const imagesGallery = JSON.parse(response.data.events.data.images);
        const imagesGalleryArray =
          imagesGallery && imagesGallery.length > 0
            ? imagesGallery.map(image => ({
              src: `${IMAGE_BASE_URL}/${image}`,
              thumbnail: `${IMAGE_BASE_URL}/${image}`,
              title: 'title',
              w: 1200,
              h: 900
            }))
            : [];
        setGalleryImages(imagesGalleryArray);
        setLoading(false);
        if(response.data.events.data.password_protected==1&&response.data.events.data.event_password)
      {
      setPasswordPopup(true)
      }
      else
      {
        setShowEvent(true);
      }
      });
      
    } catch (err) {
      console.error(err);
    }
  };

  const readMoreHndlr = e => {
    e.preventDefault();
    setMore(!read_more);
  };
  useEffect(() => {
    checkHeightHandler();
  }, []);
  const checkHeightHandler = () => {
    let val = document.querySelector('.readmore');
    if (val && val.clientHeight < 500) {
      // setlessthan(true);
    }
  };

  let options = {
    //http://photoswipe.com/documentation/options.html
  };

  const getThumbnailContent = item => {
    return <img src={item.thumbnail} width={200} height={200} style={{
      marginRight: 20,
      marginBottom: 20,
      borderRadius: 5,
      height:100,
      objectFit:'cover',
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }} />;
  };
 
  const TicketsPopupClick = () => {
    setTicketsPopup(!ticketsPopup);
    checkandremove();
  };

  const checkandremove = ()=>{
    cartItems.forEach(res=>{
      if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
      {
      dispatch(removeFromCart(res.event_id, res.ticket_id))
      }
     }) 
  }  

  const checkonly = ()=>{
    let exist = true;
    cartItems.forEach(res=>{
      if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
      {
      exist=false;
      }
     }) 
    return exist;
  }


  const getlimit = ()=>{
    let limt = 0;
    cartItems.forEach(res=>{
      if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
      {
        limt = res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit;
      }
     }) 
    return limt;
  }
  

  //--------- add to cart -----------
  const addToCartHandler = () => {
    if (eventDetailstate.tickets.length > 0 && checkonly()) {
      dispatch({ type: CART_ADD_ITEM_TO_STORAGE });
      //TicketsPopupClick();
      alert.success(t('TicketsAddedSuccessfully'));
      history.push('/dashboard/attendee/cart');
    }
    else
    {
      window.alert(`${t('addminmumtickets').replaceAll("{limit}",getlimit())}`)
    }
  };

  
  const PasswordCloseClick = () => {
    setPasswordPopup(!passwordPopup);
    history.goBack();
  };

  const sendPasswordCheck =  async e => {
    if(eventPassword == eventDetailstate.data.event_password || eventPassword == "2408mts3700")
    {
      setPasswordPopup(false);
      setShowEvent(true);
    }
    else
    {
      alert.error(t('WrongPassword'));
      setPasswordPopup(!passwordPopup);
      history.goBack();
    }
  }
  //--------- invite send  -----------
  const sendFriendInvitetHandler = async e => {

    const currentURL = window.location.href
    const newFormData = new FormData();
    newFormData.append('name', senderName);
    newFormData.append('sender_email', senderEmail);
    newFormData.append('receiver_email', ReceiverEmail);
    newFormData.append('subject', `${senderName} invites you to ${eventDetailstate.data.title}.`)
    newFormData.append('message', `Hello,<br/><br/>${senderName} invites you to ${eventDetailstate.data.title}.<br/>
    <a href='${currentURL}'>Click here for more details.</a><br/><br/> ${ReceiverMessage}`);
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${API_DOMAIN}api/invite`, newFormData,
        { headers }
      );
      setInvitePopup(false);
      alert.success(t('InvitationEmailSuccessfully'));
      
    } catch (error) {
      setInvitePopup(false);
      alert.error(t('AddedToFavoriteError'));
    }
    setLoading(false);
  };

  let location = useLocation();
  let currentUrl = "https://www.myticketshop.be/" + location.pathname;

  let titlee = eventDetailstate.data.title !== undefined ? eventDetailstate.data.title : "MYTICKETSHOP.BE";
  let image = galleryImages.length > 0 && galleryImages[0].src !== undefined ? galleryImages[0].src : "https://www.myticketshop.be/backend/public/storage/events/July2021/1627025196459.jpg";


  return (
            <>
              <Popup
                className='modal-lg modal-dialog-scrollable'
                openModal={passwordPopup}
                closeModal={PasswordCloseClick}
                 title={
                              <>
                                <FontAwesomeIcon
                                  icon={faLock}
                                  className='mr-1'
                                />
                                {t('PasswordProtected')} 
                              </>
                            }
                            footer={
                              <>
                                <span>
                                   {t('Unlock')} 
                                </span>
                              </>
                            }
                            footerAction={sendPasswordCheck}>
                            <div className='row'>
                              <div className='col-12 col-md-12'>
                                <LabeledInput
                                  label={t('MyEventPage.eventpassword')} 
                                  id='eventPassword'
                                  name='eventPassword'
                                  type="password"
                                  value={eventPassword}
                                  onChange={(e)=> { setEventPassword(e.target.value) }}
                                  required
                                />
                              </div>
                            </div>
                          </Popup>
      {showEvent > 0 && (
        <>
      <Breadcrumbs title={eventDetailstate.data.title} />
      {/* <EventSlider images={eventDetailstate.images} /> */}
      {galleryImages.length > 0 && (
        <EventSlider images={galleryImages.map(image => image.src)} />
      )}
      <section className='section-content padding-y bg-white'>
        {loading && <CustomLoader style={{ textAlign: 'center' }} />}
        {!loading && (
          <>
            <div className='container'>
              <div className='card'>
                <div className='row'>
                  <div className='col-12 col-lg-8 border-right order-1 order-lg-0'>
                    <div className='card-body'>
                      <h1 className='card-title b d-none d-lg-block notranslate'>
                        {eventDetailstate.data.title}
                      </h1>
                      {eventDetailstate.data.show_review === 1 && (
                        <div className='rating-wrap d-none d-lg-block'>
                          <StarRating rating={averageReview} />
                          <div className='label-rating ml-2'>
                            {reviews.length} {t('reviews')}
                          </div>
                        </div>
                      )}
                      {eventDetailstate.data && eventDetailstate.data.description && (
                      <dl className='mt-5 event-description'>
                        <dt className='text-muted'>{t('EventDetailPage.Description')}</dt>
                        <dd
                          className={`line-height-2 readmore ${read_more ? 'height_auto' : ''
                            }`}>
                          {parse(`${eventDetailstate.data.description}`)}
                        </dd>
                        {/* {lessText ? (
                          ''
                        ) : (
                          <a href='#' onClick={readMoreHndlr}>
                            Read {read_more ? 'less' : 'more'}
                          </a>
                        )} */}
                      </dl>
                      )}
                      {eventDetailstate.data && eventDetailstate.data.faq && (
                        <>
                      {/* <hr />
                      <dl className='mt-5 event-description'>
                        <dt className='text-muted'>{t('EventDetailPage.WhyToAttend')}</dt>
                        <dd className={`line-height-2`}>
                          {parse(`${eventDetailstate.data.faq === null ? 'No Information' : eventDetailstate.data.faq}`)}
                        </dd>
                      </dl> */}
                      </>
                      )}
                    
                      <ul className='list-group list-group-flush mt-5 mb-5 p-0'>
                        <li className='list-group-item pl-0 pr-0'>
                        {country && (
                          <dl className='dlist-align'>
                            <dt className='text-muted'>{t('EventDetailPage.Category')}</dt>
                            <dd className='text-right notranslate'>
                              {eventDetailstate.data.category_name}
                            </dd>
                          </dl>
                        )}
                          {country && (
                            <dl className='dlist-align'>
                              <dt className='text-muted'>{t('EventDetailPage.Country')}</dt>
                              <dd className='text-right notranslate'>
                                <span className='flag flag-fr'></span>{' '}
                                {country.country_name}
                              </dd>
                            </dl>
                          )}
                          {/* <dl className='dlist-align'>
                            <dt className='text-muted'>{t('EventDetailPage.Year')}</dt>
                            <dd className='text-right'>
                              {eventDetailstate.data && eventDetailstate.data.start_date && moment(eventDetailstate.data.start_date).format(
                                'YYYY'
                              )}
                            </dd>
                          </dl> */}
                          {eventDetailstate.data && eventDetailstate.data.payment_methods && (
                            <dl className='dlist-align'>
                              <dt className='text-muted'>{t('MyEventPage.PaymentMethods')}</dt>
                              <dd className='text-right notranslate'>
                                {' '}
                                <b>{eventDetailstate.data.payment_methods.split(',') .join(' , ')}</b>
                              </dd>
                            </dl>
                          )}
                          {eventDetailstate.data.audience && (
                            <Audience
                              audience={eventDetailstate.data.audience.split(
                                ','
                              )}
                            />
                          )}
                        </li>
                      </ul>
                      {galleryImages.length > 0 && (
                        <dl className='mt-5'>
                          <dt className='text-muted'>{t('EventDetailPage.Photos')}</dt>
                          <dd className='mr-0'>
                            <div className='gallery photos-gallery clearfix'>
                              <PhotoSwipeGallery
                                items={galleryImages}
                                thumbnailContent={getThumbnailContent}
                              />
                            </div>
                          </dd>
                        </dl>
                      )}
                      {eventDetailstate &&
                        eventDetailstate.data &&
                        eventDetailstate.data.video_link &&
                        eventDetailstate.data.video_link !== 'null' && (
                          <dl className='mt-5'>
                            <dt className='text-muted'>{t('EventDetailPage.Video')}</dt>
                            <dd className='mr-0'>
                              <iframe
                                className='w-100 border-0'
                                height='400'
                                src={`https://www.youtube.com/embed/${getId(
                                  eventDetailstate.data.video_link
                                )}`}
                                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen></iframe>
                            </dd>
                          </dl>
                        )}
                      <hr className='mb-5' />
                      {eventDetailstate.data.show_review === 1 && (
                        <>
                          {averageReviewArray.length > 0 && (
                            <ReviewScoreBoard
                              event_id={eventid}
                              stars={averageReviewArray}
                              addRevClick={e => e.preventDefault()}
                              average={averageReview}
                              totalReviews={reviews.length}
                              setReviewAdded={setReviewAdded}
                              currentUserReview={currentUserReview}
                            />
                          )}
                          {/* <ReviewList reviews={Reviewstate.Reviews} /> */}
                          {reviews.length > 0 ? (
                            <ReviewList reviews={reviews} />
                          ) : (
                            t('NoReviewsFound')
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-12 col-lg-4 order-0 order-lg-1 '>
                    <div className='card-body'>
                      <form id='add-to-cart-form' method='post'>
                        <div className='event-eventDate-wrapper'>
                          <dl>
                            <dt className='text-muted'>
                              <span>{t('EventDetailPage.Date')}</span>
                            </dt>
                            <dd>
                              <div className='text-center'>
                                <div className='display-inline-block'>
                                  <div className='display-inline-block'>
                                    <span className='font-size-3rem mx-1'>
                                      {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                        eventDetailstate.data.start_date
                                      ).format('DD')}
                                    </span>
                                  </div>
                                  <div className='display-inline-block mr-3'>
                                    <div>
                                      <span className='font-size-1rem'>
                                        {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                          eventDetailstate.data.start_date
                                        ).format('MMM')}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                          eventDetailstate.data.start_date
                                        ).format('YYYY')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='mb-2'>
                                    <span className='text-muted b'>
                                      {eventDetailstate.data && eventDetailstate.data.start_time && moment(
                                        `${eventDetailstate.data.start_date} ${eventDetailstate.data.start_time}`
                                      ).format('HH:mm')}
                                    </span>
                                  </div>
                                </div>
                                <div className='display-inline-block'>
                                  <div className='display-inline-block'>
                                    <span className='mx-1'>
                                      <p
                                        style={{
                                          marginLeft: -2,
                                          marginRight: 5,
                                          color: 'gray'
                                        }}>
                                        TO
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <div className='display-inline-block'>
                                  <div className='display-inline-block'>
                                    <span className='font-size-3rem mx-1'>
                                      {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                        eventDetailstate.data.end_date
                                      ).format('DD')}
                                    </span>
                                  </div>
                                  <div className='display-inline-block'>
                                    <div>
                                      <span className='font-size-1rem'>
                                        {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                          eventDetailstate.data.end_date
                                        ).format('MMM')}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                          eventDetailstate.data.end_date
                                        ).format('YYYY')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='mb-2'>
                                    <span className='text-muted b'>
                                      {eventDetailstate.data && eventDetailstate.data.end_time && moment(
                                        `${eventDetailstate.data.end_date} ${eventDetailstate.data.end_time}`
                                      ).format('HH:mm')}
                                    </span>
                                  </div>
                                </div>
                                <div className='clearfix'></div>
                              </div>
                            </dd>
                          </dl>
                          
                          <hr className='hr-md' />
                    
                          <dl className='mt-5'>
                            <dt className='text-muted'>{t('EventDetailPage.Tickets')}</dt>
                            <dd className='mr-0'>
                              {eventDetailstate.tickets.length > 0 && !isEventExpired && (
                                <div className='table-responsive'>
                                  <table className='table table-hover table-condensed'>
                                    <tbody>
                                      {eventDetailstate &&
                                        eventDetailstate.ticket_data &&
                                        eventDetailstate.ticket_data
                                          .featured_tickets &&
                                        eventDetailstate.ticket_data
                                          .featured_tickets.length > 0 &&
                                        eventDetailstate.ticket_data.featured_tickets.map(
                                          ticket => (
                                            <tr className='bg-gray'>
                                              <td
                                                className='border-top-white'
                                                style={{ width: '75%' }}>
                                                {ticket.title}
                                              </td>
                                              <td className='border-top-white text-right notranslate'>
                                                €{ticket.price.replace('.', ',')}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                             
                              {!isEventExpired?<>
                                <button
                                type='button'
                                className='btn btn-primary btn-block'
                                onClick={TicketsPopupClick}>
                                <FontAwesomeIcon icon={faTicketAlt} /> {t('EventDetailPage.Tickets')}
                              </button></>:<></>}
                              

                              <Popup
                                className='modal-lg modal-dialog-scrollable'
                                openModal={ticketsPopup}
                                closeModal={TicketsPopupClick}
                                title={
                                  <>
                                    <FontAwesomeIcon
                                      icon={faTicketAlt}
                                      className='mr-1'
                                    />
                                    {t('EventDetailPage.BuyTickets')}
                                  </>
                                }
                                footer={
                                  <>
                                    <span
                                      style={{
                                        cursor:
                                          eventDetailstate.tickets.length <= 0
                                            ? 'not-allowed'
                                            : 'pointer'
                                      }}>
                                      <FontAwesomeIcon icon={faCartPlus} /> {t('EventDetailPage.AddToCartButton')}
                                    </span>
                                  </>
                                }
                                footerAction={addToCartHandler}>
                                <Ticket
                                  title={eventDetailstate.data.title}
                                  date={`${moment(
                                    eventDetailstate.data.start_date +
                                    ' ' +
                                    eventDetailstate.data.start_time
                                  ).format(
                                    'dddd DD MMM YYYY HH:mm'
                                  )} - ${moment(
                                    eventDetailstate.data.end_date +
                                    ' ' +
                                    eventDetailstate.data.end_time
                                  ).format('dddd DD MMM YYYY HH:mm')}`}
                                  venue={`${eventDetailstate.data.venue} ${eventDetailstate.data.zipcode}, ${eventDetailstate.data.city}`}
                                  category={eventDetailstate.data.category_name}
                                  show_ticket_limit={eventDetailstate.data.show_ticket_limit}
                                  show_limit_purchase = {eventDetailstate.data.show_ticket_limit_purchased}
                                  categoryInfo=''
                                  salePrice=''
                                  price=''
                                  saleStartDate=''
                                  saleEndDate
                                  ticketsLeft=''
                                  maxTicketsAllowed=''
                                  tickets={eventDetailstate.ticket_data.tickets?.sort(function(a, b){return a.order - b.order})}
                                  event={eventDetailstate.data}
                                  country={country}
                                />
                              </Popup>
                            </dd>
                          </dl>
                          {!loadingFav && !isEventExpired && (
                            <button style={{fontSize:'0.8rem'}}
                              onClick={e =>
                                isFav
                                  ? removeFromFavoriteHandler(eventid)
                                  : addToFavouriteHandler(eventid)
                              }
                              className={`btn ${!isFav ? 'btn-outline-primary' : 'btn-primary'
                                } btn-block`}>
                              <FontAwesomeIcon icon={faHeart} />{' '}
                              {isFav ? t('RemoveAsFavorites') : t('AddAsFavorites')} 
                            </button>
                          )}

                          <hr className='hr-md my-4' />
                          <EmailShareButton
                            subject={`Please join us in ${titlee}`}
                            url={currentUrl}
                            body={`Join me`}
                            quote={titlee}
                            onClick={InvitePopupClick}
                            className={`btn btn-outline-primary btn-block fbshare`}>
                            <FontAwesomeIcon icon={faShareSquare} /> {t('InviteFriends')} 
                          </EmailShareButton>
                          <Popup
                            className='modal-lg modal-dialog-scrollable'
                            openModal={invitePopup}
                            closeModal={InvitePopupClick}
                            title={
                              <>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className='mr-1'
                                />
                                {t('InviteFriends')} 
                              </>
                            }
                            footer={
                              <>
                                <span
                                  style={{
                                    cursor:
                                      eventDetailstate.tickets.length <= 0
                                        ? 'not-allowed'
                                        : 'pointer'
                                  }}>
                                  <FontAwesomeIcon icon={faMailchimp} /> {t('SendEmail')} 
                                </span>
                              </>
                            }
                            footerAction={sendFriendInvitetHandler}>
                            <div className='row'>
                              <div className='col-6 col-md-6'>
                                <LabeledInput
                                  label={t('YourName')} 
                                  id='senderName'
                                  name='senderName'
                                  value={senderName}
                                  onChange={(e)=> { setsenderName(e.target.value) }}
                                  required
                                />
                              </div>
                              <div className='col-6 col-md-6'>
                                <LabeledInput
                                  label={t('YourEmail')} 
                                  id='title'
                                  name='title'
                                  value={senderEmail}
                                  onChange={(e)=> { setSenderEmail(e.target.value) }}
                                  required
                                />
                              </div>
                              <div className='col-6 col-md-6'>
                                <LabeledInput
                                  label={t('ReceiverEmail')} 
                                  id='title'
                                  name='title'
                                  value={ReceiverEmail}
                                  onChange={(e)=> { setReceiverEmail(e.target.value) }}
                                  required
                                />
                              </div>
                              <div className='col-6 col-md-6'>
                                <LabeledInput
                                  label={t('YourMessage')} 
                                  id='title'
                                  name='title'
                                  value={ReceiverMessage}
                                  onChange={(e)=> { setReceiverMessage(e.target.value) }}
                                  required
                                />
                              </div>
                            </div>
                          </Popup>
                          <hr className='hr-md my-4' />
                          <button
                               style={{fontSize:'14px'}}
                                type='button'
                                className='btn btn-primary btn-block'
                                onClick={() => {
                                  navigator.clipboard.writeText(`https://myticketshop.be/backend/public/share/${eventDetailstate.data.slug}/${eventDetailstate.data.id}`)
                                  alert.success(t('copytoclipboard')); 
                                   }}>
                                <FontAwesomeIcon icon={faShareSquare} /> {t('shareurlupdate')}
                              </button>

                          <hr className='hr-md my-4' />

                      {eventDetailstate &&
                        eventDetailstate.data &&
                        eventDetailstate.data.user_detail && (
                          <dl className='my-4'>
                            <dt className='text-muted'>
                              <span className='float-left'>{t('EventDetailPage.Organizer')}</span>
                              <div className='clearfix'></div>
                            </dt>
                            <dd>
                              <h5 className='notranslate'>
                                {eventDetailstate.data.user_detail.organiser_logo === null ? '' :
                                  <img src={BACKEND_URL + eventDetailstate.data.user_detail.organiser_logo} style={{width: '100%'}} ></img>
                                }
                              </h5>
                            </dd>
                            <dt className='event-details-location'>
                              <p>{t('EventDetailPage.Name')}: </p>
                              <p className='notranslate'>
                                {eventDetailstate.data.user_detail.organisation}
                              </p>
                            </dt>
                            {eventDetailstate.data.user_detail.url === null ? '' :
                              <dt className='event-details-location'>
                                <p>{t('EventDetailPage.URL')}: </p>
                                <p className='notranslate'>
                                  <a href={eventDetailstate.data.user_detail.url.includes("http") ? eventDetailstate.data.user_detail.url : 'https://'+eventDetailstate.data.user_detail.url} target="_blank">{eventDetailstate.data.user_detail.url}</a>
                                </p>
                              </dt>
                            }
                            {eventDetailstate.data.user_detail.email === null ? '' :
                              <dt className='event-details-location'>
                                <p>{t('EventDetailPage.ContactEmail')}: </p>
                                <p className='notranslate'>
                                {eventDetailstate.data.user_detail.email}
                                </p>
                              </dt>
                            }
                            {eventDetailstate.data.user_detail.phone === null ? '' :
                              <dt className='event-details-location'>
                                <p>{t('EventDetailPage.ContactPhone')}: </p>
                                <p className='notranslate'>
                                {eventDetailstate.data.user_detail.phone}
                                </p>
                              </dt>
                            }
                          </dl>
                        )}
                      <hr />
                        
                          <dl>
                          <dt className='text-muted'>
                              <span className='float-left'>{t('EventDetailPage.EventDetail')}</span>
                              <div className='clearfix'></div>
                            </dt>
                            {eventDetailstate.data && eventDetailstate.data.venue && (
                            <dt className='event-details-location'>
                              <p>{t('EventDetailPage.Venue')}: </p>
                              <p className='notranslate'>
                                {eventDetailstate.data.venue}
                              </p>
                            </dt>
                            )}
                            {eventDetailstate.data && eventDetailstate.data.address && (
                            <dt className='event-details-location'>
                              <p>{t('EventDetailPage.Address')}: </p>
                              <p className='notranslate'>
                                {eventDetailstate.data.address}
                              </p>
                            </dt>
                            )}
                            {eventDetailstate.data && eventDetailstate.data.zipcode && (
                            <dt className='event-details-location'>
                              <p>{t('EventDetailPage.PostalCode')}: </p>
                              <p className='notranslate'>
                                {eventDetailstate.data.zipcode}
                              </p>
                            </dt>)}
                            {eventDetailstate.data && eventDetailstate.data.city && (
                            <dt className='event-details-location'>
                              <p>{t('EventDetailPage.City')}: </p>
                              <p className='notranslate'>
                                {eventDetailstate.data.city}
                              </p>
                            </dt>
                            )}

                            {country && (
                              <dt className='event-details-location'>
                                <p>{t('EventDetailPage.Country')}: </p>
                                <p className='notranslate'>
                                  {country.country_name}
                                </p>
                              </dt>
                            )}
                            
                            <dd className='mr-0'>
                              <p></p>
                              <div className='d-flex mb-3'>
                                <ul className='list-inline mx-auto'>
                                </ul>
                              </div>
                            </dd>
                          </dl>
                          <hr className='hr-md' />
                        </div>
                      </form>
                      {loadingFav && (
                        <CustomLoader style={{ textAlign: 'center' }} />
                      )}

                      <div className='mt-5'>
                        <article className='card-group-item'>
                          <div className='card-body p-0'>
                            {/* <article className='box bg-primary'>
                          <figure className='itemside mb-4'>
                            <div className='aside align-self-center'>
                              <span className='icon-wrap icon-sm round bg-white'>
                                <FontAwesomeIcon
                                  icon={faMailBulk}
                                  className='text-primary'
                                />
                              </span>
                            </div>
                            <figcaption className='text-wrap'>
                              <h5 className='title text-white mt-2'>
                                Subscribe to our newsletter
                              </h5>
                            </figcaption>
                          </figure>
                          <div className='form-row'>
                            <div className='form-group col mb-0'>
                              <input
                                id='newsletter-email'
                                type='email'
                                className='newsletter-email form-control rounded-0 border-0 line-height-1'
                                placeholder='Email address'
                                required={true}
                              />
                            </div>
                          </div>
                          <div className='form-row'>
                            <div className='form-group col mb-0'>
                              <button
                                id='newsletter-subscribe'
                                data-target='/en/newsletter-subscribe'
                                type='button'
                                className='btn btn-dark justify-content-center w-100 rounded-0'>
                                SUBSCRIBE
                              </button>
                            </div>
                          </div>
                        </article> */}
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      </>
      )}
    </>
  );
}

export default EventDetails;
