// import logo from "./logo.svg";
import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import Header from './sections/header/Header';
import HomePage from './screens/home/HomePage';
import EventDetails from './screens/events/eventDetails/EventDetails';
import EventPreview from './screens/events/eventDetails/EventPreview';
import EventDetailsFrame from './screens/events/eventDetails/EventDetailsFrame';
import EventDetailWithoutHeaderFooter from './screens/events/eventDetails/EventDetailWithoutHeaderFooter';
import EventList from './screens/events/list/EventList';
import venueList from './screens/events/list/venues';
import CartPage from './screens/dashboard/attendee/CartPage';
import OrgDashboard from './screens/dashboard/organizer/Dashboard';
import OrgEditProfile from './screens/dashboard/organizer/EditProfile';
import MyEvents from './screens/dashboard/organizer/MyEvents';
import Myupcomingevents from './screens/dashboard/organizer/MyUpComingEvent';
import AddEvent from './screens/dashboard/organizer/AddEvent';
import EditEvent from './screens/dashboard/organizer/EditEvent';
import OrgChangePassword from './screens/dashboard/organizer/ChangePassword';
import AttChangePassword from './screens/dashboard/attendee/ChangePassword';
import Merchandise from './screens/dashboard/organizer/Merchandise';
import ADDMerchandise from './screens/dashboard/organizer/AddMerchandise';
import UserSettings from './screens/dashboard/attendee/Settings';
import AttendeeDashboard from './screens/dashboard/attendee/Dashboard';
import AttendeeDashboardFavorite from './screens/dashboard/attendee/Myfavorites';
import AttendeeDashboardReviews from './screens/dashboard/attendee/reviews';
import AttendeeDashboardFollows from './screens/dashboard/attendee/following';
import PrivacyPolicy from './screens/Static/PrivacyPolicy';
import Payout from './screens/dashboard/organizer/Payout';
import CheckOut from './screens/dashboard/attendee/Checkout';
import CheckoutIframe from './screens/dashboard/attendee/CheckoutIframe';
import GeneralTemplate from './screens/dashboard/organizer/custom-template/GeneralTemplate';
import EventTemplate from './screens/dashboard/organizer/custom-template/EventTemplate';

import HowItWorks from './screens/Static/HowItWorks';
import AboutUs from './screens/Static/aboutUs';
import GDPRCompliance from './screens/Static/GDPRCompliance';
import Footer from './sections/footer/Footer';
import Signin from './screens/signin/Signin';
import Signup from './screens/signin/Signup';
import SignOut from './sections/header/SignOut';
import AdminSignOut from './sections/header/AdminSignOut';
import ContactUs from './screens/contact/Contactus';
import Blogs from './screens/blogs/index';
import BlogArticle from './screens/blogs/blogArticle';
import ForgotPassword from './screens/signin/ForgotPassword';
import ResetPassword from './screens/signin/ResetPassword';
import ScrollIntoView from './sections/ui/ScrollIntoView';
import { connect } from 'react-redux';
import * as actionCreators from './service/actions/Index';
import React from 'react';
import TermsOfServices from './screens/Static/TermsofServices';
import CookiePolicy from './screens/Static/CookiePolicy';
import Coupons from './screens/dashboard/organizer/coupons/Coupons';
import MassCoupons from './screens/dashboard/organizer/masscoupon/MassCoupons';
import TicketSuppliment from './screens/dashboard/organizer/ticketsuppliment/TicketSuppliment';
import Voucher from './screens/dashboard/organizer/voucher/Voucher';
import SalesGraph from './screens/dashboard/organizer/reporting/SalesGraph';
import GenderRatio from './screens/dashboard/organizer/reporting/GenderRatio';
import ScanGraph from './screens/dashboard/organizer/reporting/ScanGraph';
import TicketGraph from './screens/dashboard/organizer/reporting/TicketGraph';
import EventGraph from './screens/dashboard/organizer/reporting/EventGraph';
import Tickets from './screens/dashboard/organizer/tickets/Tickets';
import TopEvents from './screens/dashboard/organizer/top-events/TopEvents';
import Reviews from './screens/dashboard/organizer/Reviews';
import Report from './screens/dashboard/organizer/Report';
import  FreeTicket from './screens/dashboard/organizer/freetickets/freeTicket'
import  SendFreeTicket from './screens/dashboard/organizer/freetickets/sendfreeticket'
import  SendFreeTicketMultiple from './screens/dashboard/organizer/freetickets/sendfreeticketmultiple'
import ThankYou from './screens/Static/ThankYou';
import OrderSuccess from './screens/Static/OrderSuccess';
import OrderFailed from './screens/Static/OrderFailed';
import OrderPending from './screens/Static/OrderPending';
import OrderSuccessIframe from './screens/Static/OrderSuccessIframe';
import OrderFailedIframe from './screens/Static/OrderFailedIframe';
import OrderPendingIframe from './screens/Static/OrderPendingIframe';
import Bookings from './screens/dashboard/organizer/bookings/Bookings';
import AdminCreateEvent from './screens/dashboard/organizer/AdminAddEvent';
import AdminEditEvent from './screens/dashboard/organizer/AdminEditEvent';
import BookedTickets from './screens/dashboard/organizer/bookings/BookedTickets';
// import OrderSuccess from './screens/Static/OrderSuceess'

function App(props) {
  let storeProps = props;
  return (
    <BrowserRouter>
      <ScrollIntoView>
        {window.location.pathname.indexOf('/iframe/event-details/') >= 0 ? null : window.location.pathname.indexOf('event-frame-details') >= 0 ? null : window.location.pathname.indexOf('iframe') >= 0 ? null : window.location.pathname.indexOf('Iframe') >= 0 ? null : <Header FillCategory={props} /> }
        <Switch>
          <Route
            path='/'
            exact
            render={() => <HomePage store={storeProps} />}
          />
          <Route
            path='/event-details/:event/:eventid'
            exact
            component={EventDetails}
          />
          <Route
            path='/event-frame-details/:event/:eventid'
            exact
            component={EventDetailsFrame}
          />
          <Route
            path='/event-preview/:event/:eventid'
            exact
            component={EventPreview}
          />
          <Route
            path='/iframe/event-details/:event/:eventid'
            exact
            component={EventDetailWithoutHeaderFooter}
          />
          <Route
            path='/event-list/:category'
            exact
            render={props => <EventList {...props} store={storeProps} />}
          />
        
          <Route path='/venues' exact component={venueList} />
          <Route path='/signout' exact component={SignOut} />
          <Route path='/adminsignout' exact component={AdminSignOut} />
          <Route path='/dashboard/attendee/cart' exact component={CartPage} />
          <Route path='/dashboard/organizer/' exact component={OrgDashboard} />
          <Route
            path='/dashboard/organizer/bookings/'
            exact
            component={Bookings}
          />
          <Route
            path='/dashboard/organizer/booked-tickets'
            exact
            component={BookedTickets}
          />
          <Route
            path='/dashboard/organizer/reviews'
            exact
            component={Reviews}
          />
          <Route path='/dashboard/organizer/reports' exact component={Report} />
          <Route
            path='/dashboard/attendee/my-tickets'
            exact
            component={AttendeeDashboard}
          />
          <Route
            path='/dashboard/attendee/my-favorites'
            exact
            component={AttendeeDashboardFavorite}
          />
          <Route
            path='/dashboard/attendee/my-reviews'
            exact
            component={AttendeeDashboardReviews}
          />
          <Route
            path='/dashboard/attendee/following'
            exact
            component={AttendeeDashboardFollows}
          />

          <Route
            path='/dashboard/organizer/profile'
            exact
            component={OrgEditProfile}
          />

          <Route
            path='/dashboard/organizer/coupons'
            exact
            component={Coupons}
          />

          <Route
            path='/dashboard/organizer/masscoupons'
            exact
            component={MassCoupons}
          />

          <Route
            path='/dashboard/organizer/ticketsuppliments'
            exact
            component={TicketSuppliment}
          />

          <Route
            path='/dashboard/organizer/sales-graph'
            exact
            component={SalesGraph}
          />
          
          <Route
            path='/dashboard/organizer/donut-graph'
            exact
            component={GenderRatio}
          />

          <Route
            path='/dashboard/organizer/scan-detail'
            exact
            component={ScanGraph}
          />

           <Route
            path='/dashboard/organizer/ticket-detail'
            exact
            component={TicketGraph}
          />

           <Route
            path='/dashboard/organizer/region'
            exact
            component={EventGraph}
          />
          
           <Route
            path='/dashboard/organizer/voucher'
            exact
            component={Voucher}
          />

          <Route
            path='/dashboard/organizer/general-template'
            exact
            component={GeneralTemplate}
          />

          <Route
            path='/dashboard/organizer/event-template'
            exact
            component={EventTemplate}
          />

          <Route
            path='/dashboard/organizer/top-events'
            exact
            component={TopEvents}
          />

          <Route
            path='/dashboard/organizer/my-events'
            exact
            component={MyEvents}
          />

          <Route
            path='/dashboard/organizer/my-upcoming-events'
            exact
            component={Myupcomingevents}
          />

          <Route
            path='/dashboard/organizer/my-events/add'
            exact
            component={AddEvent}
          />

           <Route
            path='/dashboard/organizer/my-events-upcoming/add'
            exact
            component={AddEvent}
          />

          <Route
            path='/admin/event/add/:verifycode'
            exact
            component={AdminCreateEvent}
          />
          <Route
            path='/admin/event/edit/:slug/:verifycode'
            exact
            component={AdminEditEvent}
          />
          <Route
            path='/dashboard/organizer/my-events/edit/:slug'
            exact
            component={EditEvent}
          />
          <Route
            path='/dashboard/organizer/my-events/tickets/:slug'
            exact
            component={Tickets}
          />
          <Route
            path='/dashboard/organizer/my-events-upcoming/edit/:slug'
            exact
            component={EditEvent}
          />
          <Route
            path='/dashboard/organizer/my-events-upcoming/tickets/:slug'
            exact
            component={Tickets}
          />
          <Route
            path='/dashboard/organizer/my-payout'
            exact
            component={Payout}
          />
          <Route
            path='/dashboard/organizer/change-password'
            exact
            component={OrgChangePassword}
          />

          <Route
            path='/dashboard/attendee/change-password'
            exact
            component={AttChangePassword}
          />
          <Route
            path='/dashboard/attendee/settings'
            exact
            component={UserSettings}
          />
          <Route
            path='/dashboard/organizer/my-merchandise'
            exact
            component={Merchandise}
          />
          <Route
            path='/dashboard/organizer/my-merchandise/add'
            exact
            component={ADDMerchandise}
          />
          <Route
            path='/dashboard/attendee/checkout'
            exact
            component={CheckOut}
          />
          <Route
            path='/dashboard/attendee/CheckoutIframe'
            exact
            component={CheckoutIframe}
          />
          <Route
            path='/dashboard/organizer/free-ticket'
            exact
            component={FreeTicket}
          />
             <Route
            path='/dashboard/organizer/freetickets/add'
            exact
            component={SendFreeTicket}
          />

          <Route
            path='/dashboard/organizer/freeticketsmultiple/add'
            exact
            component={SendFreeTicketMultiple}
          />

          <Route path='/thankyou' exact component={ThankYou} />
          <Route
            path='/order/success/:orderNumber'
            exact
            component={OrderSuccess}
          />
          <Route
            path='/order/pending/:orderNumber'
            exact
            component={OrderPending}
          />
          <Route
            path='/order/failed/:orderNumber'
            exact
            component={OrderFailed}
          />

           <Route
            path='/order-iframe/success/:orderNumber'
            exact
            component={OrderSuccessIframe}
          />
          <Route
            path='/order-iframe/pending/:orderNumber'
            exact
            component={OrderPendingIframe}
          />
          <Route
            path='/order-iframe/failed/:orderNumber'
            exact
            component={OrderFailedIframe}
          />

          <Route path='/contact' exact component={ContactUs} />
          <Route path='/CookiePolicy' exact component={CookiePolicy} />
          <Route path='/GDPRCompliance' exact component={GDPRCompliance} />
          <Route path='/TermsofServices' exact component={TermsOfServices} />
          <Route path='/PrivacyPolicy' exact component={PrivacyPolicy} />
          <Route path='/blog' exact component={Blogs} />
          <Route path='/HowItWorks' exact component={HowItWorks} />
          <Route path='/aboutus' exact component={AboutUs} />
          <Route path='/blog-article/:article' exact component={BlogArticle} />
          <Route path='/signin' exact component={Signin} />
          <Route path='/signup/:user' exact component={Signup} />
          <Route path='/ForgotPassword' exact component={ForgotPassword} />
          <Route
            path='/forgot/password/reset/:token'
            exact
            component={ResetPassword}
          />
          <Route
            path='*'
            exact
            render={props => <EventList {...props} store={storeProps} />}
          />
        </Switch>
        {window.location.pathname.indexOf('/iframe/event-details/') >= 0 ? null : window.location.pathname.indexOf('event-frame-details') >= 0 ? null : window.location.pathname.indexOf('iframe') >= 0 ? null : window.location.pathname.indexOf('Iframe') >= 0 ? null : <Footer /> }
        
      </ScrollIntoView>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return state;
};

// const mapDispatchToProps = dispatch => ({
//   SIGNIN_HANDLER: data=> dispatch(SIGNIN(data))
// })

export default connect(mapStateToProps, actionCreators)(App);
